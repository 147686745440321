#work {
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23BBBBBB' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23D1781F'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__work-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width:2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }

    .item-active {
        background-color: var(--secondary-color);
        color: #fff;
    }
}

.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //align-items: center;
    flex: 1;
    align-items: stretch;
    //flex-flow: row wrap;
    //flex-direction: row;

    .app__work-item {
        //flex: 33% 0 33.333%;
        display: flex;
        width: 270px;
        flex-direction: column;
        height: 100%;



        margin: 2rem;
        //padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;

        //cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }

        @media screen and (min-width: 2000px) {
            width: 670px;
            // padding: 1.25rem;
            border-radius: 0.75rem;
        }

        @media screen and (max-width: 300px) {
            width: 100%;
            // margin: 1rem;
        }
    }
}

.app__work-img {
    // max-height: 400px;
    //height: 100%;
    height: 400px;
    width: 100%;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 600px;
    }
}

.app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
            width: 70%;
            height: 70%;
            color: var(--white-color);
        }

        svg:hover {
            color: var(--secondary-color)
        }
    }
}

.app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    //height: 100%;



    .bott-btn {
        bottom: 0;
        padding: 0.5rem 1rem;
        border-radius: 1rem;

        background-color: #fff;
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width:2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
            font-size: 1.5em;
        }
    }

    h4 {
        margin-top: 1rem;
        line-height: 1.5;

    }

    .app__work-tag {
        position: absolute;
        padding: 0.5rem 1rem;
        //border-radius: 10px;
        background-color: rgba(0, 0, 0, 1);
        /*background-color: var(--secondary-color);*/
        top: -25px;
        width: 100%;

        marquee {
            color: #fff;
            font-family: var(--font-base);
            font-weight: 800;

        }



    }

    /*@media screen and (max-width: 600px) {
        .bott-btn {
            display: none;
        }
    }*/
}

.app__header-info-alt {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;


    margin: 0 2rem;

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge-work {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp,
    .tag-cmp {
        padding: 1rem 2rem;
        background: var(--white-color);
        //border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;
        margin-bottom: 2rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }



    span {
        font-size: 2.5rem;
        color: #d1781f;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}



.head-text {}