.contact {
    padding-top: 3rem;
    line-height: 0;
    background-color: white;


}



.ocean {
    background: linear-gradient(#2674A9, #000000);
    position: relative;



    @media screen and (max-width: 600px) {
        .anchor {
            img {
                height: 100px;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }


    form {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 1rem;
        flex-basis: 100%;
        margin: 1rem;

        input,
        textarea {

            width: 60%;
            padding: 0.95rem;
            border-radius: 7px;
            background-color: var(--primary-color);
            margin-bottom: 15px;
            font-family: var(--font-base);
            color: var(--secondary-color);
            outline: none;
        }

        textarea {
            height: 170px;
        }

        button {
            width: 60%;
            padding: 1rem 2rem;
            border-radius: 10px;
            border: none;
            background-color: var(--secondary-color);

            font-weight: 500;
            color: var(--white-color);
            outline: none;
            margin: 2rem 0 0 0;
            font-family: var(--font-base);

            transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        }

    }
}