#octocat {
    height: 300px;
    width: 200px;
    background: url("../assets/octocat.png") left center;
    animation: animateSprite 15s steps(1) infinite, swim 15s ease-in-out infinite;
    position: absolute;
    right: -200px;
    top: 50%;
    margin-top: -150px;
    z-index: 100;

    @media screen and (max-width: 600px) {
        display: none;
    }
}



@keyframes animateSprite {
    0% {
        background-position: -600px;
        /* Starting position moving left sprite */
    }

    20% {
        background-position: 0px;
        /* Straight on sprite */
    }

    25% {
        background-position: -200px;
        /* Moving up sprite */
    }

    35% {
        background-position: -400px;
        /* Moving right sprite */
    }

    40% {
        background-position: -400px;
        /* Moving right sprite */
    }

    50% {
        background-position: -200px;
        /* Moving up sprite */
    }

    60% {
        background-position: -0px;
        /* Straight on sprite */
    }

    67% {
        background-position: -600px;
        /* Moving up sprite */
    }

    100% {
        background-position: -600px;
        /* Moving up sprite */
    }
}


@keyframes swim {
    0% {
        transform: translate(0, 0);
        /* Starting position */
    }

    20% {
        transform: translate(calc(-50vw - 100px), 0);
        /* Animate to center of screen */
    }

    25% {
        transform: translate(calc(-50vw - 100px), 0);
        /* Stay at the center for 1 second */
    }

    35% {
        transform: translate(calc(-50vw - 100px), -20vh);
        /* Animate up for 2 seconds */
    }

    50% {
        transform: translate(-25vw, 15vh);
        /* Animate bottom right for 3 seconds */
    }

    60% {
        transform: translate(-25vw, -20vh);
        /* Animate up at right of the screen */
    }

    67% {
        transform: translate(-25vw, -20vh);
    }

    100% {
        transform: translate(calc(-100vw - 300px), 0);
        /* Animate past left past the screen */
    }
}

.bubble {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    background-color: rgb(236, 228, 228);
    // background-color: rgb(25, 44, 212);
    bottom: -30px;
    opacity: 0.2;
    animation: bubble 15s ease-in-out infinite,
        sideWays 4s ease-in-out infinite alternate;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

@keyframes bubble {
    0% {
        transform: translateY(0%);
        opacity: 0.06;
    }

    100% {
        transform: translateY(-120vh);
    }
}

@keyframes sideWays {
    0% {
        margin-left: 0px;
    }

    100% {
        margin-left: 200px;
    }
}

.bubble--1 {
    left: 10%;
    animation-delay: 0.5s;
    animation-duration: 16s;
    opacity: 0.2;
}

.bubble--2 {
    width: 15px;
    height: 15px;
    left: 40%;
    animation-delay: 1s;
    animation-duration: 10s;
    opacity: 0.1;
}

.bubble--3 {
    width: 10px;
    height: 10px;
    left: 30%;
    animation-delay: 5s;
    animation-duration: 20s;
    opacity: 0.3;
}

.bubble--4 {
    width: 25px;
    height: 25px;
    left: 40%;
    animation-delay: 8s;
    animation-duration: 17s;
    opacity: 0.2;
}

.bubble--5 {
    width: 30px;
    height: 30px;
    left: 60%;
    animation-delay: 10s;
    animation-duration: 15s;
    opacity: 0.1;
}

.bubble--6 {
    width: 10px;
    height: 10px;
    left: 80%;
    animation-delay: 3s;
    animation-duration: 30s;
    opacity: 0.4;
}

.bubble--7 {
    width: 15px;
    height: 15px;
    left: 90%;
    animation-delay: -7s;
    animation-duration: 25s;
    opacity: 0.3;
}

.bubble--9 {
    width: 20px;
    height: 20px;
    left: 50%;
    bottom: 30px;
    animation-delay: -5s;
    animation-duration: 19s;
    opacity: 0.2;
}

.bubble--10 {
    width: 40px;
    height: 40px;
    left: 30%;
    bottom: 30px;
    animation-delay: -21s;
    animation-duration: 16s;
    opacity: 0.3;
}

.bubble--11 {
    width: 30px;
    height: 30px;
    left: 60%;
    bottom: 30px;
    animation-delay: -13.75s;
    animation-duration: 20s;
    opacity: 0.3;
}

.bubble--11 {
    width: 25px;
    height: 25px;
    left: 90%;
    bottom: 30px;
    animation-delay: -10.5s;
    animation-duration: 19s;
    opacity: 0.3;
}


.container-modal {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px;

    h1 {
        padding-bottom: 50px;
    }
}

.wav {
    position: relative;
    top: -150px;
}

.modal-styles {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    padding: 0;
    z-index: 1000;
    width: 80%;
    max-width: 1000px;
    max-height: 900px;
    overflow: auto;

    @media screen and (max-width: 1600px) {
        max-height: 600px;
        overflow: auto;


    }

    @media screen and (max-width: 600px) {
        max-height: 600px;
        overflow: auto;

        iframe {
            width: 211px;
            height: 120px;
        }
    }

}

.overlay-styles {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background-color: rgba(0, 0, 0, .7);
    //background-color: rgba(0, 34, 129, 0.7);
    background-color: rgba(26, 37, 65, 0.7);
    z-index: 1000;

    @media screen and (max-width: 600px) {
        // max-height: 600px;
        overflow: auto;
        background-color: rgba(26, 37, 65, 0.7);
    }
}

.row-modal-tech {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .column-modal-tech {

        display: flex;
        flex-direction: column;
        padding: 2rem;

        img {
            height: 40px;
            width: 40px;
        }

        p {
            max-width: min-content;

        }
    }
}

.row-modal {
    // background-color: red;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .column-modal {
        // background-color: blue;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        padding: 1rem;
        margin-bottom: 0.5rem;

        img {
            height: 40px;
            width: 40px;
        }



        .desc {
            padding-left: 20px;
            padding-top: 20px;
        }

        .bott-btn {
            bottom: 0;
            padding: 0.5rem 1rem;
            border-radius: 1rem;

            background-color: rgba(0, 0, 0, 0.5);
            color: #FFF;
            font-weight: 800;
            cursor: pointer;
            transition: all 0.3s ease;
            margin: 0.5rem;

            &:hover {
                background-color: var(--secondary-color);
                color: #fff;

            }

            @media screen and (min-width:2000px) {
                padding: 1rem 2rem;
                border-radius: 0.85rem;
            }
        }
    }
}

.close {
    font-size: 30px;
    float: right;
    padding-right: 5px;

}