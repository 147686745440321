#home {
    position: relative;
    background-color: var(--white-color);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23FFFFFF' cx='50' cy='0' r='50'/%3E%3Cg fill='%23fdfaf9' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23fbf5f2' cx='50' cy='100' r='50'/%3E%3Cg fill='%23f9f0eb' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23f6eae4' cx='50' cy='200' r='50'/%3E%3Cg fill='%23f4e5dd' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23f2dfd6' cx='50' cy='300' r='50'/%3E%3Cg fill='%23f0dace' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23eed4c6' cx='50' cy='400' r='50'/%3E%3Cg fill='%23ebcebe' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e9c7b6' cx='50' cy='500' r='50'/%3E%3Cg fill='%23e7c1ad' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e5baa3' cx='50' cy='600' r='50'/%3E%3Cg fill='%23e2b399' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e0ac8e' cx='50' cy='700' r='50'/%3E%3Cg fill='%23dda482' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23db9d75' cx='50' cy='800' r='50'/%3E%3Cg fill='%23d99467' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d68c56' cx='50' cy='900' r='50'/%3E%3Cg fill='%23d48241' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23D1781F' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: contain;
    /*background-size: cover;*/
    background-position: center;
    background-repeat: repeat;

    // disable the default padding styles
    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }

    --bg-color: var(--white-color);

    --typewriterSpeed: 2s;
    --typewriterCharacters: 18;


}

/*.blink {
    animation: blinker 1s linear infinite;
}*/
/*@keyframes blinker {
    50% {
        opacity: 0;
    }
}*/





.pulse {
    animation: 2s pulse 4s infinite;


}

.typewriter p {
    position: relative;
    display: inline;
    width: max-content;
    color: var(--secondary-color);
    font-size: large;

}

.typewriter .typer {
    position: relative;
    display: inline;
    width: max-content;
    color: black;
}

.typewriter .typer::before,
.typewriter .typer::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.typewriter .typer::before {
    background: var(--bg-color);
    animation: typewriter var(--typewriterSpeed) steps(var(--typewriterCharacters)) 1s forwards;
}

.typewriter .typer::after {
    width: 0.125em;
    background: var(--secondary-color);
    animation: typewriter var(--typewriterSpeed) steps(var(--typewriterCharacters)) 1s forwards,
        blink 750ms steps(var(--typewriterCharacters)) infinite;
}

@keyframes typewriter {
    to {
        left: 100%;
    }
}

@keyframes blink {
    to {
        background: transparent;
    }
}

@keyframes pulse {
    0% {
        //transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        // transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        // transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}


.text-mode {
    writing-mode: vertical-rl;
    text-orientation: sideways;
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 6rem 2rem 0rem;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }

}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    margin: 0 2rem;




    .circle {
        padding: 1rem;
        height: 300px;
        width: 300px;
        border-radius: 50%;

        //display: block;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 1400px) {



            height: 200px;
            width: 200px;


        }

    }
}



.app__header-badge {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .badge-cmp,
    .tag-cmp {
        padding: 1rem 2rem;
        background: var(--white-color);
        // border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;
        margin-bottom: 2rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }



    span {
        font-size: 2.5rem;
        color: var(--secondary-color);

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: center;
        align-items: center;

    }
}


.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    // circles with different height & width
    div:nth-child(1) {

        width: 80px;
        height: 80px;
    }

    div:nth-child(2) {
        margin: 1rem;
        width: 100px;
        height: 100px;
    }

    div:nth-child(3) {

        width: 150px;
        height: 150px;
    }

    div:nth-child(4) {
        margin: 1rem;
        width: 100px;
        height: 100px;
    }

    div:nth-child(5) {
        width: 80px;
        height: 80px;
    }

    /* @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            width: 200px;
            height: 200px;
        }

        div:nth-child(2) {
            width: 300px;
            height: 300px;
        }

        div:nth-child(3) {
            width: 170px;
            height: 170px;
        }

        div:nth-child(4) {
            width: 200px;
            height: 200px;
        }

        div:nth-child(5) {
            width: 300px;
            height: 300px;
        }
    }*/

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }

        div:nth-child(1) {
            width: 100px;
            height: 100px;
        }

        div:nth-child(2) {
            width: 100px;
            height: 100px;
        }

        div:nth-child(3) {
            width: 100px;
            height: 100px;
        }

        div:nth-child(4) {
            width: 100px;
            height: 100px;
        }

        div:nth-child(5) {
            width: 100px;
            height: 100px;
        }
    }


}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;


    img {
        width: 60%;
        object-fit: contain;
        z-index: 1;


    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;

        img {
            width: 60%;
            object-fit: contain;
            z-index: 1;
        }


    }
}

.app__header-img-alt {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;


    max-width: 600px;
    margin-left: 4rem;

    .slides {

        max-height: 800px;


        //padding-bottom: 3rem;
        // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        // border-radius: 15px;
    }

    @media screen and (max-width: 2000px) {
        //margin-left: 8rem;
    }

    @media screen and (max-width: 1400px) {

        padding-bottom: 150px;
        height: 70%;

        .slides {

            // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
            //border-radius: 15px;

        }
    }

    @media screen and (max-width: 600px) {

        padding: 0;
        margin: 0;

        .slides {
            height: 50%;
            // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
            // border-radius: 15px;

        }
    }
}

.app__header-game {
    padding: 2rem;


    @media screen and (max-width: 1400px) {
        display: none;
    }
}