.app__navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    //padding: 1rem 2rem;
    background: rgba(255, 255, 255, 0.25);
    //box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;

    z-index: 2;
}

.dropdown {}


/* Dropdown content (hidden by default) */
.dropdown-content {
    padding-top: 12px;
    display: none;
    //flex-direction: column;
    // display: none;
    position: absolute;
    //background-color: #b32222;
    background-color: var(--white-color);
    min-width: 200px;
    //box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    top: 100%;

    a {
        max-height: 40px;
    }



    @media screen and (min-width: 2000px) {
        min-width: 400px;

        a {
            max-height: 60px;
        }
    }
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}


.app__navbar-logo {
    margin: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition-property: transform;
    transition-duration: 500ms;

    img {
        width: 50px;
        height: 50px;

        @media screen and (min-width: 2000px) {
            // margin-left: 30px;
            margin: 15px;

            width: 75px;
            height: 75px;
        }
    }

    &:hover {
        transform: rotate(45deg)
    }

    @media screen and (max-width: 600px) {
        transition-property: none;
    }
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    list-style: none;

    li {
        margin: 0 1rem;
        cursor: pointer;

        flex-direction: column;


        .anchor {
            width: 15px;
            height: 15px;
            background: transparent;
            // border-radius: 50%;
            visibility: hidden;

            @media screen and (min-width: 2000px) {
                // margin-left: 30px;
                height: 20px;
                width: 20px;
            }


        }


        a {

            color: var(--gray-color);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
            //flex-direction: row;
            //box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            float: none;
            color: var(--gray-color);
            padding: 0px 20px 15px;
            // padding: 100% 16px;
            text-decoration: none;
            text-align: left;
            display: block;
            height: 50px;

            @media screen and (min-width: 2000px) {
                // margin-left: 30px;
                height: 90px;
            }

            &:hover {
                color: var(--secondary-color);
                //color: rgb(210, 121, 31);
            }

            .ship {
                margin-right: 5px;
                float: right;
                display: none;
                //visibility: collapse;


            }

            &:hover .ship {
                display: inherit;
                // visibility: visible;
                color: var(--gray-color);
            }

        }

        &:hover {

            .anchor {
                //background: var(--secondary-color);
                visibility: visible;

                @media screen and (min-width: 2000px) {
                    margin-bottom: 10px;
                }
            }

        }
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);


    svg {
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 80%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background: url('../assets/bgWhite.png');
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;

        /* top box shadow */
        box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

        svg {
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    color: var(--gray-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;

                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: var(--secondary-color);

                    }
                }
            }
        }

        @media screen and (min-width: 600px) {
            display: none;
        }
    }

    @media screen and (min-width: 600px) {
        display: none;
    }
}