.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;

}

#about {
    //background-color: var(--white-color)

    /*background-color: #D1781F;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='99' height='99' fill='%23FFFFFF'/%3E%3C/svg%3E");
    background-attachment: fixed;*/
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23d1781f' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;



    .head-text {
        font-size: 2.75rem;
        font-weight: 800;
        text-align: center;
        color: var(--black-color);
        text-transform: capitalize;

        span {
            color: var(--secondary-color);
        }



        @media screen and (max-width: 450px) {
            font-size: 1.5rem;
        }
    }

    .p-text {
        margin: 0 2rem 2rem 2rem;
        font-size: 1rem;
        text-align: left;
        color: var(--gray-color);
        line-height: 1.5;
        display: block;
    }




    .school:hover {
        h2 {
            color: var(--secondary-color);
            cursor: pointer;


        }

    }

    .school {
        a {
            text-decoration: none;
        }



    }

    .abet {
        color: black;
        text-decoration: none;
    }

    .abet:hover {
        color: var(--secondary-color);
        cursor: pointer;

    }

    .paper {
        background-color: var(--white-color);
        margin: 2rem;
        max-width: 1000px;
        min-width: 700px;

        .uco {
            width: 50px;
            height: 100%;
        }

        @media screen and (min-width: 2000px) {
            // min-width: 300px;

            .uco {
                width: 70px;
                height: 100%;
            }
        }


        @media screen and (max-width: 600px) {
            min-width: 300px;

            .uco {
                width: 70px;
                height: 100%;
            }
        }

    }

    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;


    }

    .column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
    }


    .app__header-img {
        flex: 1;
        height: 100%;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        margin: 2rem;

        img {
            width: 60%;
            object-fit: contain;
            z-index: 1;



        }

        @media screen and (max-width: 600px) {
            margin: 0;

            img {
                width: 60%;
                object-fit: contain;
                z-index: 1;
            }
        }


        @media screen and (min-width: 1200px) {
            margin: 2rem 0;

            img {
                width: 60%;
                object-fit: contain;
                z-index: 1;
            }
        }

        @media screen and (min-width: 2000px) {


            img {
                width: 70%;
                object-fit: contain;
                z-index: 1;
            }
        }
    }
}

.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    overflow-y: hidden;
}

.app__profile-item {
    width: 155px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    img {
        width: 80%;
        height: 130px;
        border-radius: 15px;
        object-fit: cover;
    }

    /*For extra large devices*/
    @media screen and (min-width: 2000px) {
        width: 300px;
        margin: 2rem 4rem;

        img {
            height: 250px;
        }
    }
}