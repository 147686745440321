#publications {

    //background: rgba($color: #FFFFFF, $alpha: 0.5);
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 90 90'%3E%3Ccircle fill='%23D1781F' cx='45' cy='45' r='5'/%3E%3Cg fill='%23BBBBBB' fill-opacity='1'%3E%3Ccircle cx='0' cy='90' r='5'/%3E%3Ccircle cx='90' cy='90' r='5'/%3E%3Ccircle cx='90' cy='0' r='5'/%3E%3Ccircle cx='0' cy='0' r='5'/%3E%3C/g%3E%3C/svg%3E");

    /*h2 {
        background-color: 
    }*/
    .papers {

        padding: 2rem;
        background-color: var(--white-color);
    }

    .app__profiles_pub {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 2rem;
        overflow-y: hidden;

    }

    .app__profile-item_pub {
        width: 185px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 2rem;
        background-color: #fff;
        border-radius: 15px;

        img {
            width: 100%;
            height: 185px;
            border-radius: 15px;
            object-fit: cover;
        }

        a {
            text-decoration: none;
            color: inherit;
            font-size: 1em;

        }

        .bott-btn {
            bottom: 0;
            padding: 0.5rem 1rem;
            border-radius: 1rem;

            background-color: #fff;
            color: #000;
            font-weight: 800;
            cursor: pointer;
            transition: all 0.3s ease;
            margin: 0.5rem;





            &:hover {
                background-color: var(--secondary-color);
                color: #fff;
            }

            @media screen and (min-width:2000px) {
                padding: 1rem 2rem;
                border-radius: 0.85rem;
                font-size: 1.5em;
            }
        }

        /*For extra large devices*/
        @media screen and (min-width: 2000px) {
            width: 300px;
            margin: 2rem 4rem;

            img {
                height: 250px;
            }
        }
    }

}