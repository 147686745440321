#foot {
    background-color: black;
    display: flex;
    flex-direction: column;

    h2 {
        padding-top: 30px;
        padding-bottom: 30px;
        color: white;
    }

    embed {
        @media screen and (max-width: 900px) {
            display: none;
        }
    }


}