#presentations {
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23d1781f' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23000000' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23d18642' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23161616' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23d09361' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23252525' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23cca07f' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23343434' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23c5ae9d' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23444444' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23bbbbbb' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23555555' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
}


.app__header-info-alt {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    margin: 0 2rem;

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin: 1rem 2rem;

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge-skills {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;


    .heading {
        padding: 1rem 2rem;
        background: var(--white-color);

        //border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}





.encap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .video {

        flex-direction: column;

        margin: 1rem;
        transition: all 0.3s ease;

        iframe {
            width: 480px;
            height: 270px;
        }

        @media screen and (max-width: 600px) {
            iframe {
                width: 300px;
                height: 100%;
            }
        }

    }
}